.contact {
    padding: 50px 100px 100px;
    position: relative;
}

.contact-container {
    display: grid;
    grid-template-columns: 300px minmax(0, 1fr);
    gap: 50px 0;
    position: relative;
    transition: transform 0.5s ease;
    overflow: hidden;
    padding-top: 4px;
}

.contact .contact-container-mask {
    position: absolute;
    top: 4px;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(15,27,97);
    background: linear-gradient(90deg, rgba(15,27,97,1) 0%, rgba(37,59,190,1) 100%);
    z-index: 3;
    transition: transform 0.4s ease-in-out;
}

.contact .contact-container-mask.slide-to-right {
    transform: translateX(-110%);
}

.contact h2 {
    margin-bottom: 46px;
}

.contact-heading h3 {
    font-size: 24px;
}

.contact-heading p {
    font-size: 16px;
}

.contact .contact-form {
    position: relative;
    padding-right: 4px;
}

.contact::after {
    display: none;
    content: "";
    background-color: #F4F4F4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1001;
    opacity: 0.4;
}

.contact.contact-loading-mask::after {
    display: block;
}

.contact .loading-spinner {
    position: absolute;
    z-index: 1002;
    top: 30%;
    left: 50%;
    margin-left: -30px;
    font-size: 60px;
    color: var(--subtext-color);
}

.contact-form .row > div:first-child {
    padding-right: 8px;
}

.contact-form .row > div:last-child {
    padding-left: 8px;
}

.contact-form .form-floating label {
    left: auto;
    font-size: 15px;
    color: var(--text-color);
}

.contact-form .form-floating label::after {
    content: "*";
    margin-left: 3px;
}

.contact-form .form-floating label i {
    margin-right: 6px;
    font-size: 14px;
}

.contact-form .form-floating input,
.contact-form .form-floating textarea,
.contact-form .form-floating input:focus,
.contact-form .form-floating textarea:focus {
    color: var(--text-color);
}

.contact-form .form-floating input:user-valid,
.contact-form .form-floating textarea:user-valid {
    border-color: #198754;
}

.contact-form .form-floating input:user-invalid,
.contact-form .form-floating textarea:user-invalid {
    border-color: #dc3545;
}

.contact-form .form-floating .invalid-feedback {
    font-size: 12px;
}

.contact-form .form-control:focus {
    border-color: var(--subtext-color);
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(115, 187, 197, 0.25);
}

.contact-form .form-control:hover {
    border-color: var(--subtext-color);
}

.contact .recaptcha-label {
    font-size: 12px;
    color: gray;
    margin-bottom: 8px;
}

.contact .recaptcha-label span {
    font-size: 14px;
}

.contact .contact-button {
    color: #F4F4F4;
    background-color: var(--text-color);
    font-size: 16px;
    width: 180px;
    height: 50px;
    border: none;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.contact .contact-button:hover {
    cursor: none;
}

.contact .contact-button span {
    display: block;
    transition: transform 0.3s ease;
    position: relative;
}

.contact .contact-button span i {
    margin-left: 5px;
    transform: rotate(45deg);
}

.contact .contact-button:hover span {
    transform: translateY(-3px);
}

.contact .contact-button:active span {
    transform: translateY(0px);
}

.contact .contact-button::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--subtext-color);
    animation: slide-out 0.3s ease-in-out forwards;
    -webkit-animation: slide-out 0.3s ease-in-out;
}

.contact .contact-button:hover::before {
    animation: slide-in 0.3s ease-in-out forwards;
    -webkit-animation: slide-in 0.3s ease-in-out;
}

@keyframes slide-in {
    from { left: -100%; }
    to { left: 100%; }
}

@keyframes slide-out {
    from { left: 100%; }
    to { left: -100%; }
}


.Toastify__toast-container {
    top: 10%;
    z-index: 99999;
}

.Toastify__toast {
    color: var(--text-color);
    font-size: 16px;
}

@media (max-width: 992px) {
    .contact {
        padding: 30px 40px 40px;
    }

    .contact .contact-container {
        grid-template-columns: 1fr;
        gap: 10px;
        overflow: visible;
    }

    .contact .contact-container-mask {
        display: none;
    }

    .contact-form .row div:first-child {
        padding-right: 12px;
    }
    
    .contact-form .row div:last-child {
        padding-left: 12px;
    }
}

@media (max-width: 576px) {
    .contact {
        padding: 20px 0 40px;
        width: 85%;
    }
}