.projects {
    padding: 50px 100px;
}

.projects .projects-description {
    width: min(700px, 100%);
    margin-bottom: 40px;
    opacity: 0;
}

.projects .projects-description.fade-text {
    animation: fade-in-text 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.projects-container {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
}

.projects .project-card {
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    opacity: 1;
    transition: width 0.3s ease-out;
}

.projects .projects-container.slide-left {
    animation: slide-out-project 1s ease-in both;
}

.projects .projects-container.slide-right .project-card {
    animation: slide-in-project 0.75s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.projects .projects-container.slide-right .project-card:nth-child(1) {
    animation-delay: 0.5s;
}

.projects .projects-container.slide-right .project-card:nth-child(2) {
    animation-delay: 1s;
}

.projects .projects-container.slide-right .project-card:nth-child(3) {
    animation-delay: 1.5s;
}

.projects .projects-container.slide-right .project-card:nth-child(4) {
    animation-delay: 2s;
}

.projects .project-card,
.projects .project-card img {
    height: 330px;
}

.projects .project-card.active {
    width: min(350px, 38%);
}

.projects .project-card.inactive {
    width: min(150px, 18%);
}

.projects .project-card,
.projects .project-card .project-card-mask,
.projects .project-card .project-card-mask::before,
.projects .project-card img,
.projects .project-card .project-card-image::before {
    border-radius: 10px;
}

.projects .project-card .project-card-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    overflow: hidden;
    background: linear-gradient(rgba(16, 16, 16, 0) 0%, rgba(38, 38, 38, 0) 65%, rgb(21, 21, 21) 100%);
}

.projects .project-card .project-card-mask::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(16, 16, 16, 0) 0%, rgba(38, 38, 38, 0) 0%, rgb(21, 21, 21) 100%);
    z-index: -1;
    transition: transform 0.3s ease-out;
    opacity: 0.8;
    transform: translateY(100%);
}

.projects .project-card:hover .project-card-mask::before {
    transform: translateY(0);
}

.projects .project-card .project-card-role-container {
    display: flex;
    gap: 5px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.projects .project-card .project-card-role {
    background-color: rgba(21, 21, 21, 0.6);
    color: rgba(245, 245, 245, 0.7);
    padding: 5px 14px;
    font-size: 12px;
    font-family: "Nunito", sans-serif;
    border-radius: 20px;
}

.projects .project-card-description {
    position: absolute;
    bottom: 0;
    padding: 12px;
    color: var(--bg);
    z-index: 4;
    transition: scale 0.2s ease, transform 0.2s ease;
}

.projects .project-card-description h3 {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 2px;
    font-family: "Beckman", sans-serif;
}

.projects .project-card-description p {
    margin: 0;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
}

.projects .project-card img {
    width: 100%;
    object-fit: cover;
    position: relative;
    transition: width 0.3s ease-out;
}

.projects .project-card .project-card-image::before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    opacity: 0.1;
    z-index: 1;
    transition: opacity 0.2s ease-in-out;
}

.projects .project-card:hover .project-card-image::before {
    opacity: 0;
}

/* Active */
.projects .project-card.active:hover .project-card-description {
    scale: 1.02;
    transform: translate(5px, -3px);
}

/* Inactive */
.projects .project-card.inactive .project-card-description {
    position: absolute;
    right: 0;
    padding: 8px 6px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    margin: 8px 6px;
    color: var(--bg);
    overflow: hidden;
}

.projects .project-card.inactive h3 {
    display: block;
    writing-mode: vertical-rl;
    transform: scale(-1);
    margin-bottom: 0;
}

.projects .project-card.inactive p {
    display: none;
}

.projects .project-card.inactive .project-card-expand {
    font-size: 20px;
    color: var(--bg);
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s ease-out 0.1s;
    text-shadow: 1px 1px 1px rgb(21, 21, 21);
}

.projects .project-card.inactive .fa-up-down {
    display: none;
}

.projects .project-card.inactive:hover .project-card-description {
    transform: translateX(130%);
}

.projects .project-card.inactive:hover .project-card-expand {
    opacity: 1;
}


/* Link */
.projects .project-card-link {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.projects .project-card-link i.fa-circle {
    color: var(--subtext-color);
}

.projects .project-card-link i.fa-arrow-up-long {
    rotate: 45deg;
    color: var(--bg);
}

.projects .project-card:hover .project-card-link {
    opacity: 1;
}

/* ANIMATION */
@keyframes slide-out-project {
    from { transform: translateX(0); }
    to { transform: translateX(-2000px); }
}

@keyframes slide-in-project {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-in-text {
    to { opacity: 1; }
}

/* MEDIA QUERIES */
@media (max-width: 992px) {
    .projects .projects-description {
        margin-bottom: 30px;
    }

    .projects .projects-container {
        margin-bottom: 15px;
        flex-direction: column;
        gap: 20px;
    }

    .projects .project-card.active,
    .projects .project-card.inactive {
        width: 100%;
    }

    .projects .project-card.active,
    .projects .project-card.active img {
        height: 290px;
    }

    .projects .project-card.inactive,
    .projects .project-card.inactive img {
        height: 60px;
    }

    .projects .project-card,
    .projects .project-card img {
        transition: height 0.3s ease-out;
    }

    .projects .project-card.inactive .project-card-description {
        margin: 6px;
        padding: 6px;
    }

    .projects .project-card.inactive h3 {
        writing-mode: horizontal-tb;
        transform: scale(1);
        font-size: 18px;
    }

    .projects .project-card.inactive .fa-up-down {
        display: block;
        margin-bottom: 5px;
    }

    .projects .project-card.inactive .fa-left-right {
        display: none;
    }
}