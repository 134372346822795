/* Navbar */
nav.navbar {
    opacity: 0.95;
    background-color: var(--bg);
    /* background-color: #1c1d25; */
    z-index: 999;
    height: 100px;
    transition: background-color 0.3s ease-in-out; 
    width: 90%;
    margin: 20px auto;
    padding: 16px 0 8px;
}

.navbar::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background-color: var(--bg);
    z-index: -1;
}

.navbar .container-fluid {
    margin: 0;
    padding: 0;
}
  
.navbar img {
    height: 45px;
    transition: scale 0.6s ease;
}

.navbar img:hover {
    scale: 1.1;
    animation: rotate-center 0.6s ease-in-out both;
}

.navbar a:has(.navbar-brand) {
    margin-right: 0;
    animation: slide-in-top 1.5s ease-out 1.7s both;
    -webkit-animation: slide-in-top 1.5s ease-out 1.7s both;
}

.navbar .hamburger-menu {
    background-color: transparent;
    border: none;
    padding: 0;
}

.navbar .hamburger-menu:focus {
    box-shadow: none;
}

.navbar .hamburger-menu .hamburger {
    transition: translate 0.5s, rotate 0.5s;
    animation: slide-in-top 1.5s ease-out 2s both;
    -webkit-animation: slide-in-top 1.5s ease-out 2s both;
}

.navbar .hamburger-menu[aria-expanded="true"] .hamburger {
    translate: 3px -3px;
    rotate: .125turn;
}

.navbar .hamburger-menu .line {
    transition: 0.5s ease;
    stroke-dasharray: 59 32 59 300;
}

.navbar .hamburger-menu[aria-expanded="true"] .line {
    stroke-dasharray: 59 106 59 300;
    stroke-dashoffset: -90;
}
  
.nav-links {
    gap: 40px;
}

a.nav-link:nth-of-type(1) {
    animation: slide-in-top 1.5s ease-out 2s both;
    -webkit-animation: slide-in-top 1.5s ease-out 2s both;
}

a.nav-link:nth-of-type(2) {
    animation: slide-in-top 1.5s ease-out 2.3s both;
    -webkit-animation: slide-in-top 1.5s ease-out 2.3s both;
}

a.nav-link:nth-of-type(3) {
    animation: slide-in-top 1.5s ease-out 2.6s both;
    -webkit-animation: slide-in-top 1.5s ease-out 2.6s both;
}
  
.navbar .navbar-nav a.nav-link {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 4px;
    padding: 5px 0;
    color: var(--text-color);
    position: relative;
    transform: translate(0);
    transition: scale 0.5s ease;
}

.navbar-nav a.nav-link:hover,
.navbar-nav a.nav-link.active {
    color: var(--text-color);
}

a.nav-link::after {
    content: "";
    position: absolute;
    left: calc(50% - 2px);      /* half of letter-spacing */
    bottom: 0;
    width: calc(65% - 4px);     /* letter-spacing */
    height: 3px;
    background: var(--subtext-color);
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.4s ease;
}

a.nav-link:hover::after {
    transform: translateX(-50%) scaleX(1);
}

.nav-links:has(a.nav-link:hover) > a.nav-link:not(:hover) {
    scale: 0.85;
}

.navbar .social-media {
    display: none;
}

/* Animation */

/* Nav links */
@-webkit-keyframes slide-in-top {
    0% {
        transform: translateY(-1000px);
        -webkit-transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-1000px);
        -webkit-transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

/* Nav brand */
 @-webkit-keyframes rotate-center {
    0% {
        transform: rotate(0);
        -webkit-transform: rotate(0);
        scale: 1;
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        scale: 1.1;
    }
}

 @keyframes rotate-center {
    0% {
        transform: rotate(0);
        -webkit-transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

/* Media Queries */
@media (max-width: 992px) {
    nav.navbar {
        margin: 5px auto;
    }

    .navbar::before {
        height: 105px;
    }
}

@media (max-width: 576px) {
    nav.navbar {
        margin: 0 auto;
    }

    .navbar::before {
        height: 100px;
    }

    .navbar.navbar-expanded {
        opacity: 1;
    }

    .navbar .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 25%;
        height: 100vh;
        cursor: pointer; 
        z-index: 999;
    }

    .navbar .nav-links.navbar-nav {
        position: fixed;
        inset: 10px 10px 10px 25%;
        background: rgb(170,220,236);
        background: linear-gradient(135deg, rgba(170,220,236,1) 0%, rgba(222,234,238,1) 100%);
        height: calc(100vh - 10px);
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        z-index: -1;
        gap: 25px;
        padding: 70px 30px;
    }

    .navbar .navbar-nav a.nav-link {
        animation: none;
        font-size: 16px;
    }
    

    .navbar a.nav-link:first-child {
        margin-top: 30px;
    }

    .navbar .nav-links.navbar-nav[aria-expanded="true"] {
        animation: navbar-slide-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    
    .navbar .nav-links.navbar-nav[aria-expanded="false"] {
        animation: navbar-slide-out 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }

    .navbar .social-media {
        display: flex;
        flex-grow: 1;
        gap: 18px;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 50px;
    }

    .navbar .nav-links.navbar-nav svg {
        transition: transform 0.5s ease;
        margin: 2px 0;
    }
    
    .navbar .nav-links.navbar-nav svg:hover {
        transform: scale(1.2) translateY(-4px); 
    }

    .instagram-icon:hover {
        fill: url(#instagram-gradient-1);
    }

    @keyframes navbar-slide-in {
        0% {
            transform: translateX(150%);
            -webkit-transform: translateX(150%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes navbar-slide-out {
        0% {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            opacity: 1;
        }
        100% {
            transform: translateX(150%);
            -webkit-transform: translateX(150%);
            opacity: 0;
        }
    }
}