/* Hero section */
.hero {
    height: 100vh;
    scroll-margin-top: 150px;
    padding: 80px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin: auto;
    position: relative;
    overflow: hidden;
}

.hero h1 {
    font-size: 80px;
    display: inline-block;
    overflow: hidden;
    border-right: 6px solid var(--subtext-color);
    white-space: nowrap;
    margin: 0 auto;
    padding-right: 5px;
    animation: 
        typing 2.5s steps(40, end) 2.8s,
        blink-caret 0.75s step-end infinite;
    -webkit-animation: 
        typing 2.5s steps(40, end) 2.8s,
        blink-caret 0.75s step-end infinite;
}

.hero p {
    font-size: 50px;
    margin-bottom: 90px;
    animation: slide-in-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 5.5s both;
    -webkit-animation: slide-in-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 5.5s both;
}

.hero-button-container {
    display: flex;
    gap: 20px;
}
  
.hero .hero-button {
    width: 200px;
    height: 60px;
    border: none;
    text-decoration: none;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    animation: slide-in-fwd-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 5.5s both;
    -webkit-animation: slide-in-fwd-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 5.5s both;
}

.hero .hero-button:hover {
    cursor: none;
}

.hero .resume-button {
    background-color: var(--text-color);
    color: var(--bg);
}

.hero .blog-button {
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid var(--text-color);
    transition: color 0.3s ease, background-color 0.3s ease;
}
  
.hero .hero-button .button-text {
    position: relative;
    display: block;
    transition: transform 0.3s ease;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero .hero-button .view-text {
    margin-right: 4px;
}

.hero .hero-button .button-text i {
    margin-left: 5px;
    transform: rotate(45deg);
}

.hero .hero-button:hover .button-text {
    transform: translateY(-3px);
}
  
.hero .hero-button:active .button-text {
    transform: translateY(0px);
}

.hero .resume-button::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--subtext-color);
    animation: slide-out 0.3s ease-in-out;
    -webkit-animation: slide-out 0.3s ease-in-out;
}

.hero .resume-button:hover::before {
    animation: slide-in 0.3s ease-in-out;
    -webkit-animation: slide-in 0.3s ease-in-out;
}

.hero .scroll-container {
    position: absolute;
    bottom: 4%;
    left: 50%;
    width: 82px;
    margin-left: -41px; /* Half width */
    color: #919795;
    font-size: 12px;
    text-transform: uppercase;
    animation: bounce-in-bottom 1s 7s both;
    -webkit-animation: bounce-in-bottom 1s 7s both;
}
  
.hero .scroll-text span {
    margin: 0 3px;
    display: inline-block;
    animation: scroll-wobbling 7.7s ease-in-out infinite;
    -webkit-animation: scroll-wobbling 7.7s ease-in-out infinite;
}

.hero .scroll-text span:first-child {
    animation-delay: 0.0725s;
}

.hero .scroll-text span:nth-child(2) {
    animation-delay: 0.145s;
}

.hero .scroll-text span:nth-child(3) {
    animation-delay: 0.2175s;
}

.hero .scroll-text span:nth-child(4) {
    animation-delay: 0.29s;
}

.hero .scroll-text span:nth-child(5) {
    animation-delay: 0.625s;
}

.hero .scroll-text span:last-child {
    animation-delay: 0.435s;
}

.hero .scroll-icon {
    position: relative;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 8px;
    height: 24px;
    width: 14px;
    border: 1.5px solid #919795;
    border-radius: 12px;
}

.hero .scroll-icon-dot {
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    background-color: #919795;
    height: 4px;
    width: 4px;
    margin-left: -2px;
    border-radius: 50%;
    animation: scroll-up-down 1.8s ease-in-out infinite;
    -webkit-animation: scroll-up-down 1.8s ease-in-out infinite;
}

/* ANIMATION */

/* Hero title typewriter effect */
@keyframes typing {
    0% { width: 0; }
    100% { width: 660px; }
}

@-webkit-keyframes typing {
    0% { width: 0; }
    100% { width: 660px; }
}

@keyframes blink-caret {
    0%, 100% { border-color: transparent }
    50% { border-color: var(--subtext-color); }
}

@-webkit-keyframes blink-caret {
    0%, 100% { border-color: transparent }
    50% { border-color: var(--subtext-color); }
}

/* Hero subtitle on page loading */
@keyframes slide-in-left {
    0% {
        transform: translateX(1000px);
        -webkit-transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-left {
    0% {
        transform: translateX(1000px);
        -webkit-transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}

/* Hero button on page loading */
@keyframes slide-in-fwd-bottom {
    0% {
        transform: translateZ(-1400px) translateY(800px);
        -webkit-transform: translateZ(-1400px) translateY(800px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0) translateY(0);
        -webkit-transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-fwd-bottom {
    0% {
        transform: translateZ(-1400px) translateY(800px);
        -webkit-transform: translateZ(-1400px) translateY(800px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0) translateY(0);
        -webkit-transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

/* Button hover */
@keyframes slide-in {
    0% { left: -100%; }
    100% { left: 100%; }
}

@-webkit-keyframes slide-in {
    0% { left: -100%; }
    100% { left: 100%; }
}

@keyframes slide-out {
    0% { left: 100%; }
    100% { left: -100%; }
}

@-webkit-keyframes slide-out {
    0% { left: 100%; }
    100% { left: -100%; }
}

/* Scroll */
@keyframes scroll-wobbling {
    0%, 10% {
        transform: none;
        opacity: 1;
        color: #919795;
    }
    1%, 8% {
        transform: translateY(-2px);
        color: #AADCEC;
    }
    
    4% {
        transform: translateY(3px);
        opacity: 1;
        color: #919795;
    }
}

@-webkit-keyframes scroll-wobbling {
    0%, 10% {
        transform: none;
        opacity: 1;
        color: #919795;
    }
    1%, 8% {
        transform: translateY(-2px);
        color: #AADCEC;
    }
    
    4% {
        transform: translateY(3px);
        opacity: 1;
        color: #919795;
    }
}

@keyframes bounce-in-bottom {
    0% {
        opacity: 0;
        transform: translateY(500px);
        -webkit-transform: translateY(500px);
        animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
    }
    38% {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;
    }
    55% {
        transform: translateY(65px);
        -webkit-transform: translateY(65px);
        animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
    }
    72% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;
    }
    81% {
        transform: translateY(28px);
        -webkit-transform: translateY(28px);
        animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
    }
    90% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;
    }
    95% {
        transform: translateY(8px);
        -webkit-transform: translateY(8px);
        animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
    }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;
    }
}

@-webkit-keyframes bounce-in-bottom {
    0% {
        opacity: 0;
        transform: translateY(500px);
        -webkit-transform: translateY(500px);
        animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
    }
    38% {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;
    }
    55% {
        transform: translateY(65px);
        -webkit-transform: translateY(65px);
        animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
    }
    72% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;
    }
    81% {
        transform: translateY(28px);
        -webkit-transform: translateY(28px);
        animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
    }
    90% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;
    }
    95% {
        transform: translateY(8px);
        -webkit-transform: translateY(8px);
        animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
    }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;
    }
}

/* Scroll dot */
@keyframes scroll-up-down {
    0%, 100% { transform: translateY(0.2em); }
    50% { transform: translateY(1.2em); }
}

@-webkit-keyframes scroll-up-down {
    0%, 100% { transform: translateY(0.2em); }
    50% { transform: translateY(1.2em); }
}
 

/* MEDIA QUERIES */

@media (max-width: 992px) {
    .hero {
        padding: 80px 30px;
    }
    
    .hero h1 {
        font-size: 60px;
        animation-name: typing-medium, blink-caret;
    }

    .hero p {
        font-size: 40px;
    }

    @keyframes typing-medium {
        0% { width: 0; }
        100% { width: 555px; }
    }
}

@media (max-width: 576px) {
    .hero {
        padding: 80px 0;
        width: 85%;
    }

    .hero h1 {
        width: 330px;
        font-size: 37px;
        padding-right: 2px;
        border-right-width: 4px;
        animation-name: typing-small, blink-caret;
    }

    .hero p {
        font-size: 27px;
    }

    .hero .hero-button-container {
        gap: 10px;
    }

    .hero .hero-button {
        width: 135px;
        height: 45px;
        font-size: 16px;
    }

    .hero .hero-button .view-text {
        display: none;
    }

    .hero .scroll-container {
        bottom: 12%;
        bottom: calc(12% + env(safe-area-inset-bottom));
    }

    @keyframes typing-small {
        0% { width: 0; }
        100% { width: 310px; }
    }

    @-webkit-keyframes typing-small {
        0% { width: 0; }
        100% { width: 310px; }
    }
}

/* @media only screen and (max-device-width: 430px) and (orientation: portrait) {
    .hero .scroll-container {
        bottom: 12%;
    }
} */

