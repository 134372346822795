:root {
    --bg: #F4F9FC;
    --text-color: #0F1B61; 
    --subtext-color: #73BBC5;
    --line-color: #7f00e0;
    --font-family: "Satoshi-Medium", sans-serif;
    --font-size: 18px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    cursor: none;
}

html {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: var(--subtext-color) #FFFFFF;
    max-width: 1520px;
    margin: 0 auto;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #FFFFFF;
}

::-webkit-scrollbar-thumb {
    background-color: var(--subtext-color);
    border-radius: 6px;
    border: 3px solid #FFFFFF;
}

html::before {
    left: 0;
}

html::after {
    right: 0;
}

html::before,
html::after {
    content: "";
    position: fixed;
    top: 0;
    backface-visibility: hidden;
    height: 100%;
    width: 20px;
    background: #FFFFFF;
    z-index: 99999;
}

body {
    background-color: var(--bg);
    /* background-color: #1c1d25; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-family);
    font-size: var(--font-size);
    color: var(--text-color);
    overflow: hidden auto;
}

body::before {
    top: 0;
}

body::after {
    bottom: 0;
}

body::before,
body::after {
    content: "";
    position: fixed;
    left: 0;
    backface-visibility: hidden;
    height: 20px;
    width: 100%;
    background: #FFFFFF;
    z-index: 99999;
}

main, footer {
    transition: filter 0.5s ease;
}

main.blurred-content,
footer.blurred-content {
    filter: blur(1.5px);
}

.section-title.animated {
    animation: slide-in-top-title 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.section-title.animated-out {
    animation: slide-out-top-title 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.text-dot {
    color: var(--subtext-color);
}

h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 50px;
}

h2::after {
    content: "";
    position: relative;
    display: block;
    height: 3px;
    width: 80px;
    bottom: -20px;
    left: 0;
    background: var(--subtext-color);
}

.about, .projects, .contact {
    scroll-margin-top: 80px;
    background-color: var(--bg);
    width: 80%;
    margin: auto;
}

/* Animation */
/* Title */
@keyframes slide-out-top-title {
    0% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        opacity: 0;
    }
  }

@keyframes slide-in-top-title {
    0% {
        transform: translateY(-50px);
        -webkit-transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

/* Small laptops */
@media (min-width: 993px) and (max-width: 1320px) {
    .hero, .about, .contact, .projects {
        padding-left: 70px;
        padding-right: 70px;
    }

    .projects-container {
        gap: 15px;
    }
}

/* Tablet devices */
@media (max-width: 992px) {
    .about, .projects {
        padding: 30px 40px;
    }
}

/* Mobile devices */
@media (max-width: 576px) {
    html::before, html::after {
        width: 10px;
    }

    body::before, body::after {
        height: 10px;
    }

    h2 {
        font-size: 30px;
    }

    .about, .projects {
        padding: 20px 0;
        width: 85%;
    }

    .contact {
        width: 85%;
    }
}
