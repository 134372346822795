/* Footer */
footer {
    background-color: var(--bg);
    margin-bottom: 20px;
    animation: fade-in 1s ease-out 7s both;
    -webkit-animation: fade-in 1s ease-out 7s both;
}

footer .social-media-container {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;
}

footer .social-media {
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    width: 25px;
    gap: 15px;
    justify-content: flex-start;
    margin-left: calc(10vw / 2);
    margin-bottom: 20px;
}

footer .social-media::after {
    content: "";
    height: 80px;
    width: 1.5px;
    background: var(--text-color);
    margin: 0 auto;
    display: block;
}

footer svg {
    transition: transform 0.5s ease;
    margin: 2px 0;
}

footer svg:hover {
    transform: scale(1.2) translateY(-4px); 
}

.github-icon:hover {
    fill: #000000;
}

.linkedin-icon:hover {
    fill: #0A66C2;
}

.instagram-icon:hover {
    fill: url(#instagram-gradient-2);
}

.facebook-icon:hover {
    fill: #1877F2;
}

.email-icon:hover {
    fill: #1E88E5;
}

.copyright-container {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.copyright {
    color: var(--text-color);
    font-size: 12px;
    transform: rotate(90deg);
    transform-origin: top right;
    margin-right: 5vw;
    display: flex;
    align-items: center;
    gap: 15px;
    height: fit-content;
}

.copyright::after {
    content: "";
    height: 1.5px;
    width: 80px;
    display: block;
    background: var(--text-color);
}

.copyright span {
    /* margin-top: -8px;
    margin-bottom: 4px; */
    margin-left: -8px;
    transform: scale(1.4);
}

/* ANIMATION */

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@-webkit-keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@media (max-width: 576px) {
    footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        margin: 80px 0 40px;
    }

    footer .social-media-container,
    footer .copyright-container {
        position: static;
    }

    footer .social-media {
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        margin: auto;
    }

    footer .social-media::after,
    footer .copyright::after {
        display: none;
    }

    footer .copyright {
        writing-mode: horizontal-tb;
        justify-content: center;
        transform: rotate(0) scale(1);
        text-orientation: upright;
        margin: auto;
        gap: 8px;
    }

    footer .copyright span {
        margin: 0;
    }
}