.about {
    padding: 50px 100px;
}

.about h3 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
}

.about .about-container {
    display: grid;
    grid-template-columns: 200px minmax(0, 1fr);
    gap: 40px 0;  
    position: relative;
    transition: transform 0.5s ease;
    overflow: hidden;
}

.about .about-container-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(15,27,97);
    background: linear-gradient(90deg, rgba(15,27,97,1) 0%, rgba(37,59,190,1) 100%);
    z-index: 1;
    transition: transform 0.4s ease-in-out;
}

.about .about-container-mask.slide-to-right {
    transform: translateX(-110%);
}

.about .stack-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.about .skills-container {
    overflow: hidden;
    position: relative;
    padding-top: 2px;
    height: 130px;
    mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1) 10%,
        rgba(0, 0, 0, 1) 90%,
        rgba(0, 0, 0, 0)
    );
}

.about .skills-container svg {
    height: 80px;
    width: 80px;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
}

.about .skills-container .icon:hover svg {
    opacity: 0.75;
}

.about .skills-container .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    width: 80px;
}

.about .skills-container .icon .title {
    font-size: 16px;
    text-align: center;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.about .skills-container .icon .primary {
    opacity: 1;
}

.about .skills-container .icon .secondary {
    opacity: 0;
    margin-top: -14px;
    color: var(--subtext-color);
    line-height: 1.1;
}

.about .skills-container .icon:hover .primary {
    transform: translateY(-50%);
    opacity: 0;
}

.about .skills-container .icon:hover .secondary {
    transform: translateY(-20px);
    opacity: 1;
}

.about .frontend-wrapper .icon {
    transform: translateX(calc(170px * 5));
    animation: scroll-left 30s linear infinite;
}

.about .backend-wrapper .icon {
    transform: translateX(-170px);
    animation: scroll-right 30s linear infinite;
}

.about .frontend-wrapper .icon:first-child,
.about .backend-wrapper .icon:last-child {
    animation-delay: calc(30s / 5 * (5 - 1) * -1);
}
  
.about .frontend-wrapper .icon:nth-child(2),
.about .backend-wrapper .icon:nth-child(4) {
    animation-delay: calc(30s / 5 * (5 - 2) * -1);
}

.about .frontend-wrapper .icon:nth-child(3),
.about .backend-wrapper .icon:nth-child(3) {
    animation-delay: calc(30s / 5 * (5 - 3) * -1);
}

.about .frontend-wrapper .icon:nth-child(4),
.about .backend-wrapper .icon:nth-child(2) {
    animation-delay: calc(30s / 5 * (5 - 4) * -1);
}

.about .frontend-wrapper .icon:last-child,
.about .backend-wrapper .icon:first-child {
    animation-delay: calc(30s / 5 * (5 - 5) * -1);
}

/* ANIMATION */

/* Skill icons */
@keyframes scroll-left {
    100% { transform: translateX(-170px); }
}

@keyframes scroll-right {
    100% { transform: translateX(calc(170px * 5)); }
}

@keyframes scroll-left-medium {
    100% { transform: translateX(-140px); }
}

@keyframes scroll-right-medium {
    100% { transform: translateX(calc(140px * 5)); }
}

@keyframes scroll-left-small {
    100% { transform: translateX(-80px); }
}

@keyframes scroll-right-small {
    100% { transform: translateX(calc(80px * 5)); }
}

/* MEDIA QUERIES */

@media (max-width: 992px) {
    .about {
        padding: 30px 40px;
    }

    .about .about-container {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .about .stack-container {
        margin-top: 10px;
    }
    
    .about .skills-container svg {
        height: 70px;
        width: 70px;
    }

    .about .frontend-wrapper .icon {
        transform: translateX(calc(140px * 5));
        animation-name: scroll-left-medium;
    }

    .about .backend-wrapper .icon {
        transform: translateX(-140px);
        animation-name: scroll-right-medium;
    }
}

@media (max-width: 576px) {
    .about {
        padding: 20px 0;
        width: 85%;
    }

    .about .about-container {
        gap: 10px;
    }

    .about .about-container-mask {
        display: none;
    } 
    
    .about .stack-container {
        gap: 10px;
        margin: 10px 0 20px;
    }

    .about .skills-container {
        height: 85px;
    }

    .about .skills-container .icon {
        width: 60px;
    }

    .about .frontend-wrapper .icon {
        transform: translateX(calc(80px * 5));
        animation-name: scroll-left-small;
    }

    .about .backend-wrapper .icon {
        transform: translateX(-80px);
        animation-name: scroll-right-small;
    }
    
    .about .skills-container svg {
        width: 50px;
        height: 50px;
    }

    .about .skills-container .icon .title {
        font-size: 13px;
    }
}